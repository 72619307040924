import React from "react"
import { Helmet } from "react-helmet"
import Header from "../../components/header"
import Footer from "../../components/footer"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import "../../styles/style.css"

const Agile = () => {
  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "cabecera-qualoom.jpg" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <div>
      <Helmet>
        {/*Primary Meta Tags*/}
        <title>Especialistas en Metodología Agile y Devops | Qualoom</title>
        <meta name="title" content="Especialistas en Metodología Agile y Devops | Qualoom"/>
        <meta name="description" content="Cómo Implementar Metodología Agile en tu Empresa, trabaja la relación entre desarrolladores, la gestión de productos y DevOps y tu equipo de operaciones."/>
        <html lang='es'/>        
        <link rel="alternate" href="https://www.qualoom.es/devops/agile/"/>
        <link rel="canonical" href="https://www.qualoom.es/devops/agile/"/>
        <meta name="ahrefs-site-verification" content="11d569467b4aa0b0c9daa5b49b9f41eb8a413858d36c9f6ece089482f4d3b630"/>
        
        {/*Open Graph / Facebook*/}
        <meta property="og:locale" content="es_ES"/>
        <meta property="og:type" content="website"/>
        <meta property="og:url" content="https://www.qualoom.es/devops/agile/"/>
        <meta property="og:title" content="Especialistas en Metodología Agile y Devops | Qualoom"/>
        <meta property="og:description" content="Cómo Implementar Metodología Agile en tu Empresa, trabaja la relación entre desarrolladores, la gestión de productos y DevOps y tu equipo de operaciones."/>
        <meta property="og:image" content="https://images.ctfassets.net/wy4ivoo9i8ge/3qEkBiWp6XWYzJYweuZVSK/00681335572a0fe04ef29bc8dba9096b/tw_cards_servicios.png"/>
        <meta property="og:image:width" content="1200"/>
        <meta property="og:image:height" content="628"/>
        <meta property="fb:admins" content="338114870907726"/>
        
        {/*Twitter*/}
        <meta property="twitter:card" content="summary_large_image"/>
        <meta property="twitter:url" content="https://www.qualoom.es/devops/agile/"/>
        <meta property="twitter:title" content="Especialistas en Metodología Agile y Devops | Qualoom"/>
        <meta property="twitter:description" content="Cómo Implementar Metodología Agile en tu Empresa, trabaja la relación entre desarrolladores, la gestión de productos y DevOps y tu equipo de operaciones."/>
        <meta property="twitter:image" content="https://images.ctfassets.net/wy4ivoo9i8ge/3qEkBiWp6XWYzJYweuZVSK/00681335572a0fe04ef29bc8dba9096b/tw_cards_servicios.png"/>
        <meta property="twitter:creator" content="https://twitter.com/qualoom"/>

        <script type="application/ld+json">
        {`{"@type": "BreadcrumbsList", "@id":"https://www.qualoom.es/devops/agile/#breadcrumb",              
            "itemListElement":[
              {"@type":"ListItem", "position":"1", "name":"Inicio", "item": {"@id": "https://www.qualoom.es/", "name": "Inicio"}},
              {"@type":"ListItem", "position":"2", "name":"DevOps", "item": {"@id": "https://www.qualoom.es/devops/", "name": "DevOps"}}
              {"@type":"ListItem", "position":"3", "name":"Agile DevOps", "item": {"@id": "https://www.qualoom.es/devops/devops/agile/", "name": "Agile DevOps"}}
            ]}`}
        </script>
      </Helmet>
      <BackgroundImage fluid={data.image.childImageSharp.fluid}>
        <div className="background-servicios">
          <div className="header_div"><Header /></div>
          <div className="main">
            <div className="empty"></div>
            <div className="text">
              <h1>Agile DevOps</h1>
              <p>Aumenta la velocidad y eficiencia de tus procesos de desarrollo y time-to-market a través de la aplicación de metodologías ágiles. Adicionalmente con DevOps conseguirás un marco de automatización y colaboración entre los equipos de desarrollo y operaciones.</p>
            </div>
          </div>
        </div>
      </BackgroundImage>
      <div className="services-main">
        <div className="services-main-content">
          <h2>Metodología Agile con DevOps</h2><hr></hr>
          <p>En Qualoom, combinamos la metodología Agile con DevOps para brindarte una forma eficiente y efectiva de desarrollo de software. Nuestra metodología ágil permite una entrega rápida y continua, mientras que DevOps proporciona automatización y colaboración entre los equipos de desarrollo y operaciones.</p><br/>
          <h3>Consigue velocidad y automatización en los procesos</h3>
          <p>Con Agile DevOps, podrás acelerar tus procesos de desarrollo y despliegue de software. Nuestro enfoque se basa en la automatización de tareas repetitivas, la integración continua y la entrega continua, lo que te permite obtener resultados más rápidos y de mayor calidad.</p>
        </div>
      </div>
      <div className="services-main gray">
        <div className="services-main-content">
          <h2>Transformación DevOps</h2><hr></hr>
          <p>Si estás buscando una transformación completa de tus procesos de desarrollo y operaciones, nuestro equipo de expertos en DevOps está listo para ayudarte. Te acompañamos en todo el proceso de adopción de DevOps, desde el análisis y diseño hasta la implementación y optimización de las herramientas y prácticas adecuadas.</p><br/>
        </div>
      </div>
      <div className="services-main">
        <div className="services-main-content">
          <h2>Servicios y soluciones Devops</h2><hr></hr>
          <p>En Qualoom, ofrecemos una <a href="/devops/">amplia gama de servicios y soluciones en el ámbito de DevOps</a>. Nuestro equipo altamente capacitado puede ayudarte con la configuración de pipelines de integración y entrega continua, la automatización de pruebas, la gestión de la infraestructura como código y mucho más.</p><br/>
          <h3>Atención personalizada en DevOps</h3>
          <p>Entendemos que cada empresa tiene necesidades únicas. Es por eso que brindamos atención personalizada en DevOps, adaptándonos a tus requerimientos y objetivos específicos. Nuestro equipo trabajará estrechamente contigo para asegurarse de que obtengas los resultados deseados y que tu transformación DevOps sea exitosa.</p>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Agile
